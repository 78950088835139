import * as React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"

const NotFoundPage = () => {
  return (
    <Layout backgroundImage="">
      <Head
        pageTitle="Platforma Treningowa Online"
        description=""
        keywords="trening online, platforma treningowa online, video trening"
      />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
